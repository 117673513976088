import { FC, ReactNode } from 'react';
// Styles
import classes from './styles.module.scss';
import { Box } from '@mui/material';
import { NavLink } from 'react-router-dom';

const AiChecklistPage:FC = () => {

  const ItemTitleBg = ({ size, children }: { size: number, children:ReactNode }) => (
    <Box sx={{
      height: `${size}px`,
      background: 'url(/images/ai_subtitle_bg.png) center / contain no-repeat',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      mb: 2
    }}>
      {children}
    </Box>
  )

  const ItemText = ({ children }: { children:ReactNode }) => (
    <div className={classes.itemTextWrapper}>
      <div className={classes.circle} />
      <p className={classes.itemText}>{children}</p>
    </div>
  )

  return (
    <section className={classes.page}>
      <div className={`${classes.content} container`}>
        <h2 className={classes.title}>Evaluating AI Legal Software?</h2>
        <h2 className={classes.title}>Mitigate Risk</h2>
        <h3 className={classes.subtitle}>THE ETHICS & SAFE Adoption Checklist</h3>

        <div className={classes.data}>
          <div className={classes.dataContent}>
            <div className={classes.item}>
              <ItemTitleBg size={60}>
                <h4 className={classes.itemTitle}>Trust</h4>
              </ItemTitleBg>
              <div className={classes.itemData}>
                <div>
                  <ItemText>Do you trust the company implementing the AI?</ItemText>
                  <ItemText>Preserve <span>Critical Thinking</span></ItemText>
                  <ItemText>Can your AI solution survive scrutiny of <span>Trial</span>?</ItemText>
                  <ItemText>Data Sent Back to Training Set</ItemText>
                </div>
              </div>
            </div>
            <div className={classes.item}>
              <ItemTitleBg size={60}>
                <h4 className={classes.itemTitle}>Security</h4>
              </ItemTitleBg>
              <div className={classes.itemData}>
                <div>
                  <ItemText>HIPAA Compliant</ItemText>
                  <ItemText>Encryption</ItemText>
                  <ItemText>Multi-Factor Authentication</ItemText>
                  <ItemText>Privacy</ItemText>
                </div>
              </div>
            </div>
            <div className={classes.item}>
              <ItemTitleBg size={80}>
                <h4 className={classes.itemTitle}>Disruption</h4>
              </ItemTitleBg>
              <div className={classes.itemData}>
                <div>
                  <ItemText>Redundancy</ItemText>
                  <ItemText>Auto Scaling</ItemText>
                  <ItemText>How disruptive is solution to integrate with your current IT infrastructure</ItemText>
                  <ItemText>Training</ItemText>
                </div>
              </div>
            </div>
            <div className={classes.item}>
              <ItemTitleBg size={60}>
                <h4 className={classes.itemTitle}>Cost</h4>
              </ItemTitleBg>
              <div className={classes.itemData}>
                <div>
                  <ItemText>Software/AI Tokens</ItemText>
                  <ItemText>Support</ItemText>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.dataContent}>
            <div className={classes.item}>
              <ItemTitleBg size={100}>
                <h4 className={classes.itemTitle}>
                  Overcome AI <br /> Limitations
                </h4>
              </ItemTitleBg>
              <div className={classes.itemData}>
                <div>
                  <ItemText>Overcome Training Bias</ItemText>
                  <ItemText>Overcome Tech "Alignment" Bias</ItemText>
                  <ItemText>Grounding and State Bias</ItemText>
                  <ItemText>
                    Overcome Hallucinations
                    <ul className={classes.itemTextList}>
                      <li>Fiction Content</li>
                      <li>Links Issues</li>
                      <li>Association Mistakes</li>
                    </ul>
                  </ItemText>
                  <ItemText>Find Missed Files</ItemText>
                  <ItemText>Handles Handwriting</ItemText>
                  <ItemText>Large Volume of documents</ItemText>
                  <ItemText>Nuance</ItemText>
                  <ItemText>Prompt Management</ItemText>
                  <ItemText>AI Manipulation Tactics</ItemText>
                  <ItemText>Handle Newer Versions</ItemText>
                  <ItemText>AI Outage</ItemText>
                  <ItemText>Ability to Change AI models</ItemText>
                  <div className={classes.itemTextWrapper} style={{ marginTop: '40px', marginBottom: '24px' }}>
                    <Box
                      component="img" src="/images/bg_yellow.png" alt="bg_yellow"
                      draggable="false"
                      sx={{ width: '110%', position: 'absolute', pointerEvents: 'none' }}
                    />
                    <div className={classes.circle} />
                    <p className={classes.itemText}><span>Way to Fact Check and Validate Responses</span></p>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.item}>
              <ItemTitleBg size={60}>
                <h4 className={classes.itemTitle}>Usability</h4>
              </ItemTitleBg>
              <div className={classes.itemData}>
                <div>
                  <ItemText>Collaboration</ItemText>
                  <ItemText>Ease of Use for Non-Tech</ItemText>
                  <ItemText>Remote Work</ItemText>
                  <ItemText><span>Speed</span></ItemText>
                </div>
              </div>
            </div>
          </div>
        </div>

        <NavLink to="/#contact-us" target="_blank" rel="noreferrer">
          <ItemTitleBg size={120}>
            <h4 className={classes.itemTitle}>Contact <br /> Case Chronology</h4>
          </ItemTitleBg>
        </NavLink>

      </div>
    </section>
  );
}

export default AiChecklistPage;
